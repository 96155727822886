import MobileNav from "@/components/dashboard/general/navigation/MobileNav";
import SidebarNav from "@/components/dashboard/general/navigation/SidebarNav";
import TopNav from "@/components/dashboard/general/navigation/TopNav";
import { useState } from "react";
import { navigationData } from "@/fixtures/navigationData";
import { Outlet } from "react-router-dom";

export default function DashboardLayout() {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div className="flex h-screen flex-col bg-gray-300 dark:bg-gray-900">
            {/* Mobile and Sidebar Navigation */}
            <MobileNav
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                navigation={navigationData}
            />
            <SidebarNav navigation={navigationData} />

            <div className="flex flex-1 flex-col lg:pl-72">
                {/* Top Navigation */}
                <TopNav setSidebarOpen={setSidebarOpen} />

                {/* Main content */}
                <main className="flex-1 overflow-y-hidden py-5">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    );
}
