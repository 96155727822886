import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";

interface MostProfitableProductComponentProps {
    mostProfitableProductData: MostProfitableProductData | null;
}

interface MostProfitableProductData {
    id: string;
    imageUrl: string;
    itemName: string;
    profitAndRoi: {
        totalProfitLoss: number;
        totalReturnOnInvestment: number;
        totalRevenue: number;
        totalCosts: number;
    };
}

interface StatItem {
    name: string;
    stat: string;
    prefix: string;
    suffix: string;
}

const MostProfitableProductComponent: React.FC<
    MostProfitableProductComponentProps
> = ({ mostProfitableProductData }) => {
    // If data is not available, return a fallback message
    if (!mostProfitableProductData) {
        return (
            <div className="rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:col-span-2 sm:col-start-3 sm:row-start-1 sm:p-6">
                <div className="text-sm font-medium text-gray-500 dark:text-white/50">
                    No Most Profitable Product Available
                </div>
            </div>
        );
    }

    const { id, imageUrl, itemName, profitAndRoi } = mostProfitableProductData;
    const decodedId = decodeBase64Id(id);

    const getStatClass = (name: string, value: number) => {
        if (isNaN(value)) return "text-gray-900 dark:text-white";
        if (name === "Total Costs") return "text-red-500";
        return value >= 0 ? "text-green-500" : "text-red-500";
    };

    const stats: StatItem[] = [
        {
            name: "Total Revenue",
            stat: Number(profitAndRoi.totalRevenue).toFixed(2),
            prefix: "£",
            suffix: "",
        },
        {
            name: "Total Profit",
            stat: Number(profitAndRoi.totalProfitLoss).toFixed(2),
            prefix: "£",
            suffix: "",
        },
        {
            name: "ROI",
            stat: Number(profitAndRoi.totalReturnOnInvestment).toFixed(2),
            prefix: "",
            suffix: "%",
        },
        {
            name: "Total Costs",
            stat: Number(profitAndRoi.totalCosts).toFixed(2),
            prefix: "£",
            suffix: "",
        },
    ];

    const productPageUrl = `/dashboard/products/${decodedId}`;

    return (
        <div className="rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:col-span-2 sm:col-start-3 sm:row-start-1 sm:p-6">
            <div className="text-sm font-medium text-gray-500 dark:text-white/50">
                Most Profitable Product
            </div>
            <a
                href={productPageUrl}
                className="mt-2 block text-lg font-semibold text-gray-900 hover:underline dark:text-white"
            >
                {itemName}
            </a>

            <div className="flex-column mt-4 md:flex">
                <div className="col-span-1 mr-4 hidden md:block">
                    <img
                        src={imageUrl}
                        alt={itemName}
                        className="max-h-48 max-w-full rounded-lg object-cover md:max-h-64"
                    />
                </div>
                <div className="col-span-2 grid grid-cols-2 grid-rows-2 gap-4">
                    {stats.map((item) => (
                        <div
                            key={item.name}
                            className="flex-1 justify-center rounded-lg border-2 border-gray-300 p-4 dark:border-0 dark:bg-gray-700"
                        >
                            <div className="text-sm font-medium text-gray-500 dark:text-white/50">
                                {item.name}
                            </div>
                            <div
                                className={`text-3xl font-semibold tracking-tight ${getStatClass(item.name, parseFloat(item.stat))}`}
                            >
                                {item.prefix}
                                {item.stat}
                                {item.suffix}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MostProfitableProductComponent;
